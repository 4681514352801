.log-book-images-item-div {
  width: 226px;
  height: 224px;
}
.log-book-images-div{
  max-width: 1920px;
}

.log-book-image-item-div-space{
 margin: 0px 18px;
}

.log-book-images-item-div{
  width: 226px;
  height: 224px;
  border-radius: 6px;
}
.log-book-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  /* border: 2px solid transparent; */
}
.log-book-images-info-div{
  margin-top: 22px;
  margin-bottom: 16px;
}

.log-book-header {
  z-index: 500;
  background: white;
  position: sticky;
  top: 0px;
  border-bottom: 1px solid #F1F3F6;
  padding: 19px 32px;
}

.log-book-filters-div{
  background: white;
  position: sticky;
  top: 75px;
}
.log-book-user-info-button {
  border-radius: 45.5px;
}
.log-book-magnifying-img {
  width: 15px;
  height: 15px;
  margin-left: 20px;
}

.log-book-checkbox-div {
  padding: 10px 0px;
  border-top: 1px solid #F1F3F6;
  border-bottom: 1px solid #F1F3F6;
}
.log-book-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 4px;
}

.log-book-clock-image {
  width: 13px;
  height: 13px;
  margin-right: 9px;
}
.log-book-status-image {
  width: 15px;
  height: 15px;
  margin-top: 6px;
}

.log-book-status-image-polygon {
  width: 18px;
  height: 15px;
  margin-top: 6px;
}
.log-book-images-tags-div {
  background-color: rgba(37, 39, 45, 0.75);
}
