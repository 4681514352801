.dropdown-projects-item {
  border-bottom: 1px solid #F1F3F6;
}
.dropdown-hashtags {
  max-height: 210px;
  background-color: white !important;
}
.dropdown-hashtags-selected-div {
  background-color: white !important;
}

.dropdown-list {
  max-height: 215px;
  background-color: white !important;
}

