@import "colors.css";

.log-book-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.log-book-image-div {
  aspect-ratio: 3/4;
  height: 90%;
  margin-bottom: 4%;
  margin-left: 45px;
  margin-right: 45px;
}
.log-book-image-back-div {
  margin-top: 46px;
  padding-left: 46px;
}
.log-book-image-info-div {
  width: 453px;
  border-left: 1px solid var(--borders-color);
}
.log-book-image-info-details-div {
  border-bottom: 1px solid var(--borders-color);
}
.log-book-image-info-text-div {
  margin-left: 29px;
  margin-right: 29px;
}
.log-book-image-info-text-item {
  padding-top: 16.25px;
  padding-bottom: 14.75px;
  border-bottom: 1px solid var(--borders-color);
}
.log-book-image-info-note-div {
  margin-left: 29px;
  margin-right: 29px;
  border-bottom: 1px solid var(--borders-color);
}
.log-book-image-info-tags-div {
  margin-left: 29px;
  margin-right: 29px;
}
.log-book-image-info-tags-item {
  min-width: 40px;
  border: 1px solid var(--bg-secondary);
}
.log-book-image-info-buttons-div {
  padding-top: 26px;
  border-top: 1px solid var(--borders-color);
}
.log-book-image-info-buttons-trash-image {
  width: 16px;
  height: 18px;
  margin-right: 29px;
}