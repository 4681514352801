.text-h2 {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
}

.text-h5 {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
}

.text-big {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
}

.text-login-subtitle {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.text-large-bold {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
}

.text-paragraph {
  font-family: "Comfortaa", cursive;
  font-size: 21px;
  line-height: 120%;
  font-weight: 400;
  font-style: normal;
}
.text-small {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
}
.text-web-small {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}
.text-login {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.text-time {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
}

.text-back {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
}

.text-xsmall {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}
.text-xxsmall {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
}
.text-xxsmall-bold {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
}
.text-xsmall-bold {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* line-height: 120%; */
}
.text-xsmall-privacy {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 120%;
}

.text-xsmall-privacy-bold {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 120%;
}
.text-footer {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
}

.text-web-button {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
}

.text-web-xsmall {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
}

.text-web-xsmall-bold {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
}

.text-xxxsmall {
  font-family: "Comfortaa", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  color: rgba(50, 50, 60, 0.6);
}
