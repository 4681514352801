.modal-welcome-border {
  position: relative;
}
.modal-welcome-border:after {
  content: '';
  border:1px solid var(--bg-primary);
  height: 5px;
  width: 1px;
  position: absolute;
  right: 0;
  top: 35%;
}
