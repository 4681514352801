.login {
  background-image: url(../../public/assets/bg-login.png);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-size:cover;
  background-position: 10;
}

.login-div{
  margin-bottom: 90px;
}

.form-div {
  height: 465px;
  border-radius: 24px;
  padding: 0px 30px;
}
.login-form {
  height: 90%;
}

.login-inputs:focus {
  background-color: var(--text) !important;
  border: 1.5px solid transparent;
  outline: none;
}

.login-input-email-div {
  background-color: var(--text) !important;
}
.login-inputs {
  width: 95%;
  background-color: var(--text) !important;
  border: 2px solid transparent;
  border-radius: 6px;
  height: 43px;
  padding: 15px;
}
.login-form-title {
  padding-top: 45px;
  padding-bottom: 34px;
}
.password-eye-div {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1.5px solid transparent;
  padding: 15px;
  height: 43px;
}


@media only screen and (max-width: 900px) {
  .text-right-login-button {
    font-size: 9px;
  }
}
