:root {
  --primary: #3569e3;
  --primary-light: rgba(53, 105, 227,0.2);
  --secondary: #d15531;
  --tertiary: #3ecd9a;
  --bg-primary: #25272d;
  --bg-secondary: #32323c;
  --bg-tertiary: #3f3f49;
  --text: #fafafa;
  --secondary-error: rgba(209, 85, 49, 1);
  --borders-color: #F1F3F6;
}