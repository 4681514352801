@import "colors.css";
.button-sing-up-blue {
  padding: 16px 0px;
  width: 175px;
  height: 58px;
  background: var(--primary);
  border-radius: 32px;
  border: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  color: var(--text);
}

.button-blue {
  background: var(--primary) !important;
  border: 0;
  padding-top: 3px;
  cursor: pointer;
  color: var(--text);
  width: 132px;
  height: 43px;
  border-radius: 8px;
  border: 1.5px solid var(--primary);
}

.button-blue:hover {
  background: #305fcd !important;
}

.button-black-log-in {
  background: #32323c !important;
  padding-top: 3px;
  cursor: pointer;
  color: #fafafa;
  width: 132px;
  height: 43px;
  border-radius: 8px;
  border: 1px solid #32323c;
}

.button-black {
  background: #F5F5F5 !important;
  padding-top: 3px;
  cursor: pointer;
  color: #B8B8B8;
  width: 132px;
  height: 43px;
  border-radius: 8px;
  border: 1px solid #B8B8B8;
}

.button-black:hover {
  background-color: rgba(241, 243, 246, 0.5) !important;
  /* color: #25272D; */
  cursor: default;
}

.button-download-black {
  background: #32323c !important;
  padding-top: 3px;
  cursor: pointer;
  color: #fafafa;
  width: 132px;
  height: 43px;
  border-radius: 8px;
  border: 1px solid #32323c !important;
}

.button-download-black-disabled {
  background: #25272d !important;
  padding-top: 3px;
  cursor: pointer;
  color: #fafafa;
  width: 132px;
  height: 43px;
  border-radius: 8px;
  border: 1px solid #25272d !important;
}

.button-error {
  background: var(--secondary-error) !important;
  cursor: pointer;
  color: var(--text);
  width: 132px;
  height: 43px;
  border-radius: 8px;
  border: 1.5px solid var(--secondary-error);
  padding-top: 2px;
}

.button-error:hover {
  background: #c64c28 !important;
}

.button-logout {
  background: #f1f3f6 !important;
  cursor: pointer;
  color: var(--secondary-error);
  width: 132px;
  height: 43px;
  border-radius: 8px;
  border: 1.5px solid var(--secondary-error);
  padding-top: 3px;
}

.button-delete {
  background: var(--secondary-error) !important;
  cursor: pointer;
  color: white;
  width: 110px;
  height: 43px;
  border-radius: 6px;
  border: 1.5px solid var(--secondary-error);
}

.button-delete:hover {
  background: #c64c28 !important;
}

.button-cancel {
  background: #ffffff !important;
  cursor: pointer;
  color: var(--bg-primary);
  width: 110px;
  height: 43px;
  border-radius: 6px;
  border: 1.5px solid var(--bg-primary);
}

.button-cancel:hover {
  background-color: rgba(241, 243, 246, 0.5) !important;
}

.button-loading {
  background: var(--primary-light) !important;
  border: 0;
  cursor: pointer;
  color: var(--text);
  width: 132px;
  height: 43px;
  border-radius: 8px;
  border: 1.5px solid var(--primary);
}

.button-filter-status {
  width: 83px;
  height: 32px;
  border-radius: 33px;
  background-color: #D8D8D8;
}
