@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/buttons.css";
@import "./styles/text-styles.css";
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Poppins&display=swap");

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Comfortaa", cursive;
}
input[type="checkbox"] {
  accent-color: #3569e3;
}

.overflow-styles::-webkit-scrollbar {
  width: 7px;
}
.overflow-styles::-webkit-scrollbar-track {
  border-radius: 50px;
  background: rgba(241, 243, 246, 1);
}
.overflow-styles::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: rgba(50, 50, 60, 1);
}

input {
  transition: background-color 50000s, color 50000s, filter 50000s;
}

input:-webkit-autofill {
  animation-duration: 50000s;
  animation-name: onautofillstart;
}

input:not(:-webkit-autofill) {
  animation-duration: 50000s;
  animation-name: onautofillcancel;
}

@keyframes onautofillstart {
  from {
  }
}
@keyframes onautofillcancel {
  from {
  }
}
