.profile-div {
  position: fixed;
  top: 75px;
  right: 0;
}
.profile-title-div {
  border-bottom: 1px solid rgb(37, 39, 45, 0.03);
}
.profile-title-name {
  margin-bottom: 10px;
}
.profile-icons-div {
  background: rgba(37, 39, 45, 0.06);
  border: 1px solid rgba(37, 39, 45, 0.06);
  border-radius: 99px;
}
.profile-title-div-padding {
  padding-bottom: 46px;
}
