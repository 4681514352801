.dropdowns-div {
  margin-top: 9px;
  margin-bottom: 2px;
  height: 46px;
}

.log-book-filter-status-icon {
  width: 15px;
  height: 15px;
}

.log-book-filter-status-icon-polygon {
  width: 18px;
  height: 15px;
}

.log-book-input-tag {
  outline: none;
}
.log-book-filters-button-delete-images {
  width: 43px !important;
  height: 43px !important;
}

@media (max-width: 520px) {
  .logbook-filter-buttons-rigth-div {
    justify-content: center;
  }
  .logbook-filter-buttons-rigth-div-item {
    margin: 5px 5px;
  }
}

@media (max-width: 744px) {
  .logbook-filter-status-buttons {
    margin-left: 0px;
    width: 130px;
  }
}
